/**
 * components/Button/Content.js
 */
import PropTypes from 'prop-types';

const ButtonContent = ({ label }) => {
  return (
    <div className="">
      <span className="">{label}</span>
    </div>
  );
};

ButtonContent.propTypes = {
  label: PropTypes.node,
};

export default ButtonContent;
