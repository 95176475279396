import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNeedSignIn: false,
  isNeedSignUp: false,
  isLogin: false,
  socialAuthType: '',
  socialAuthData: null,
  socialAuthIdToken: null,
  redirectTo: null,
  referral: '',
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setNeedSignIn: (state, action) => {
      state.isNeedSignIn = action.payload;
    },
    setNeedSignUp: (state, action) => {
      state.isNeedSignUp = action.payload;
    },
    setSocialAuth: (state, action) => {
      const { type, data, isLogin, idToken } = action.payload;
      state.isLogin = isLogin;
      state.socialAuthType = type;
      state.socialAuthData = data;
      state.socialAuthIdToken = idToken;
    },
    setRedirectTo: (state, action) => {
      state.redirectTo = action.payload;
    },
    setReferral: (state, action) => {
      state.referral = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addDefaultCase((state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.auth,
  //     };
  //   });
  // },
});

export const { setNeedSignIn, setNeedSignUp, setSocialAuth, setRedirectTo, setReferral } = loginSlice.actions;

export default loginSlice.reducer;

export const getIsNeedSignIn = (state) => state.login.isNeedSignIn;
export const getIsNeedSignUp = (state) => state.login.isNeedSignUp;
export const getSocialAuthType = (state) => state.login.socialAuthType;
export const getSocialAuthData = (state) => state.login.socialAuthData;
export const getSocialAuthIdToken = (state) => state.login.socialAuthIdToken;
export const getRedirectTo = (state) => state.login.redirectTo;
export const getReferral = (state) => state.login.referral;
