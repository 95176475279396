/**
 * @param {{
 * close: MouseEventHandler<HTMLDivElement>;
 * isShow: boolean;
 * children: any;
 * rootStyles: CSSProperties;
 * zIndex: number;
 * }} props 
 * @returns 
 */

const Modal = (props) => {
  const {
    classRoot,
    close,
    isShow,
    children,
    rootStyles,
    zIndex = 999
  } = props;

  if (isShow) {
    return (
      <>
        <div
          className={`${classRoot} fixed inset-0 flex items-center justify-center p-4 lg:p-6`}
          style={{
            zIndex,
            ...rootStyles,
          }}
        >
          <button type="button" className='absolute bg-black/60 cursor-default h-full w-full z-[-1]' onClick={close}></button>
          {children}
        </div>

        <style>{`
          html, body {
            overflow: hidden !important;
          }
        `}</style>
      </>
    );
  }
  return null;
};

export default Modal;
