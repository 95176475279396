import Image from "@/core/components/Image";

/**
 * 
 * @param {{
 * classGroup: HTMLAttributes<HTMLDivElement>.className?: string;
 * classGroupAdditional: HTMLAttributes<HTMLDivElement>.className?: string;
 * height: number;
 * width: number;
 * }} props 
 * @returns 
 */
const LoadingSpinner = ({
    classGroup = "bg-transparent h-full",
    classGroupAdditional = "",
    height = 20,
    width = 20
}) => {

    return (
        <div className={`${classGroup} ${classGroupAdditional}`}>
            <div className="flex h-full items-center justify-center w-full">
                <Image src={'/images/icons/IconSpinner.svg'} alt="Spinner" className={`spinner`} height={height} width={width} />
            </div>
        </div>
    )
}

export default LoadingSpinner;