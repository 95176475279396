// Import Library
import dynamic from 'next/dynamic';

// Import Component
const TimeoutPage = dynamic(() => import("@/app/timeout-page"));

export const metadata = {
    title: 'Koneksi Timeout - IDEAL Indonesia'
}

export default function Timeout() {
    return <TimeoutPage />
}
