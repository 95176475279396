import { CloseCircle } from 'iconsax-react';
import { useSelector, useDispatch } from 'react-redux';

import { setIsRegistered } from '@/core/store/reducer/authSlice';
import { getIsNeedSignIn, setNeedSignIn, setRedirectTo } from '@/core/store/reducer/loginSlice';
import { logEvent, logEventNudges } from '@/core/helpers/analytics';

import Content from "./Content";
import Modal from "@/core/components/Modal/Base";

const LoginModal = () => {
  const dispatch = useDispatch();
  const isNeedSignIn = useSelector(getIsNeedSignIn);

  const onClose = () => {
    dispatch(setNeedSignIn(false));
    dispatch(setRedirectTo(null));
  }

  const onSuccessLogin = () => {
    logEvent('SIGNIN_COMPLETE')
    logEventNudges('SignIn_Complete');

    dispatch(setIsRegistered(true));
    onClose();
  }

  return (
    <Modal
      isShow={isNeedSignIn}
    >
      <div
        className="rounded-lg relative w-full bg-white py-10 px-8 sm:px-16 flex flex-col overflow-auto"
        style={{
          maxHeight: '90%',
          maxWidth: 480,
          minHeight: 546,
        }}
      >
        <button type="button" className={'absolute right-4 top-3'} onClick={onClose}>
          <CloseCircle className='h-6 w-6' color='#25253F' variant='Bold' />
        </button>
        <Content onSuccessLogin={onSuccessLogin} fromModal />
      </div>
    </Modal>
  );
};

export default LoginModal;
